import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
import ReCAPTCHA from 'react-google-recaptcha'

import SiteLink from '@components/SiteLink'
import SubmitButton from '@components/submit-button/submit-button.component'
import { emailRegex } from '@assets/constants/validation'
import { trackGa } from '@components/GoogleTracking'
import ValidatorForm from '@components/validators/validator-core/ValidatorForm'
import { TextValidator } from '@components/validators'
import config from "@utils/config"

import airplane from '@assets/images/icons/ionicons-paper-airplane.png'
import './email-finder.component.scss'

const ReCAPTCHAKey = `${config.REACT_APP_GOOGLE_RECAPTCHA}`

export class EmailFinderComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showShareModal: false,
      showMessageSent: false,
      emailSubmitting: false,
      fromEmail: '',
      fromName: '',
      toEmail: '',
      toName: '',
      canContinue: false,
    }

    this.openShareModal = this.openShareModal.bind(this)
    this.closeShareModal = this.closeShareModal.bind(this)
    this.updateInputValue = this.updateInputValue.bind(this)
    this.sharePhysicianFinder = this.sharePhysicianFinder.bind(this)
    this.joinTracking = this.joinTracking.bind(this)
    this.updateTracking = this.updateTracking.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.allowContinue = this.allowContinue.bind(this)
    this.disableContinue = this.disableContinue.bind(this)
  }

  handleChange(label, value) {
    this.setState({
      [label]: value,
    })
  }

  allowContinue() {
    this.setState({
      canContinue: true,
    })
  }

  disableContinue() {
    this.setState({
      canContinue: false,
    })
  }

  render() {
    return (
      <div className="email-finder-component">
        <div className="primary-container email-finder-wrapper" >
          <div className="share-finder email-finder-area">
          </div>
          <div className="join-directory email-finder-area">
            <div className="join-directory-content">
              <h2>Healthcare professional specializing in sleep medicine? </h2>
              <SiteLink to="/stay-connected/join-the-sleep-specialist-directory/" tracking={`${this.joinTracking()}`}>
                Join our directory
              </SiteLink>{' '}
              <span>or</span> <br />
              <SiteLink to="/stay-connected/update-sleep-specialist-directory/" tracking={`${this.updateTracking()}`}>
                update your current listing
              </SiteLink>
              .
            </div>
          </div>
          <div className="disclaimer email-finder-area">
            <p>
              The Sleep Specialist locator was developed using third party, objective, publicly available diagnosis
              claims data. Jazz Pharmaceuticals does not endorse any of these physicians and is not responsible for
              medical outcomes resulting from treatment by these physicians. Most, but not all, professionals in this
              tool are Board Certified in sleep medicine. The information included on this website is intended for
              residents of the United States only.{' '}
            </p>
          </div>
        </div>
        <Modal className="content-modal ss-share-modal" show={this.state.showShareModal} onHide={this.closeShareModal}>
          <div className="close-modal-wrapper">
            <div className="close-modal" onClick={this.closeShareModal}>
              X
            </div>
          </div>
          <Modal.Header closeButton>
            <Modal.Title>Share the Sleep Specialist Locator</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="body-content ss-share-modal-content">
              <div className="header-text-wrapper">
                <h2 className="title">Share the Sleep Specialist Locator</h2>
                <p className="content">
                  Would you like to share this tool with a colleague or patient?
                  <br />
                  Email a link by completing the requested information.
                </p>
              </div>
              <div className={this.messageSending(this.state.showMessageSent)}>
                <img src={airplane} />
                Message Sent
              </div>
              <ValidatorForm className="action" onSubmit={this.sharePhysicianFinder} instantValidate={false}>
                <div className="email-inputs">
                  <div className="input-info">
                    <div className="input-header">Recipient&apos;s Information</div>
                    <div className="input-wrapper">
                      <TextValidator
                        type="text"
                        className="centered-placeholder-input"
                        label="Recipient's Name"
                        id="toName"
                        name="toName"
                        value={this.state.toName}
                        onChange={(e) => this.handleChange('toName', e.target.value)}
                        onFocus={(e) => this.handleFocus(e)}
                        onBlur={(e) => this.handleBlur(e)}
                        validators={['required']}
                        errorMessages={['Please fill out this field']}
                      />
                      <TextValidator
                        type="email"
                        className="centered-placeholder-input"
                        label="Recipient's Email Address"
                        id="toEmail"
                        name="toEmail"
                        value={this.state.toEmail}
                        onChange={(e) => this.handleChange('toEmail', e.target.value)}
                        onFocus={(e) => this.handleFocus(e)}
                        onBlur={(e) => this.handleBlur(e)}
                        validators={['required', 'isEmail']}
                        errorMessages={['Please fill out this field', 'Your email address does not have a valid domain name.']}
                      />
                    </div>
                  </div>
                  <div className="input-info">
                    <div className="input-header">Your Information</div>
                    <div className="input-wrapper">
                      <TextValidator
                        type="text"
                        className="centered-placeholder-input"
                        label="Name"
                        id="fromName"
                        name="fromName"
                        value={this.state.fromName}
                        onChange={(e) => this.handleChange('fromName', e.target.value)}
                        onFocus={(e) => this.handleFocus(e)}
                        onBlur={(e) => this.handleBlur(e)}
                        validators={['required']}
                        errorMessages={['Please fill out this field']}
                      />
                      <TextValidator
                        type="email"
                        className="centered-placeholder-input"
                        label="Your Email Address"
                        id="fromEmail"
                        name="fromEmail"
                        value={this.state.fromEmail}
                        onChange={(e) => this.handleChange('fromEmail', e.target.value)}
                        onFocus={(e) => this.handleFocus(e)}
                        onBlur={(e) => this.handleBlur(e)}
                        validators={['required', 'isEmail']}
                        errorMessages={['Please fill out this field', 'Your email address does not have a valid domain name.']}
                      />
                    </div>
                  </div>
                </div>
                <ReCAPTCHA
                  className="goog-recaptcha"
                  sitekey={ReCAPTCHAKey}
                  onChange={this.allowContinue}
                  onExpired={this.disableContinue}
                  onErrored={this.disableContinue}
                  required
                />
                <div className="ss-action-buttons">
                  <button className="button btn-primary no" onClick={this.closeShareModal}>
                    Cancel
                  </button>
                  {this.state.canContinue ? (
                    <SubmitButton
                      onClick={() => trackGa('find sleep specialist,	click,	share sleep specialist locator - send')}
                      className="button btn-primary send-button"
                      loading={this.state.emailSubmitting}
                      text="Send Information"
                      disabled={!this.state.canContinue}
                    />
                  ) : (
                    <button className="button btn-primary send-button" disabled>
                      Send Information
                    </button>
                  )}
                </div>
              </ValidatorForm>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }

  handleFocus(e) {
    e.target.classList.add('focused-input-email')
  }

  handleBlur(e) {
    e.target.classList.remove('focused-input-email')
  }

  messageSending(itSent) {
    return itSent ? 'message-sent-overlay' : 'hide'
  }

  openShareModal() {
    this.setState({
      showShareModal: true,
    })
  }

  sharePhysicianFinder(event) {
    event.preventDefault()

    if (!emailRegex.test(this.state.toEmail.toLowerCase())) {
      return
    }

    let emailPostBody = {
      From: this.state.fromEmail.toLowerCase(),
      To: this.state.toEmail.toLowerCase(),
      ToName: this.state.toName,
      FromName: this.state.fromName,
      Subject: '',
      Message: '',
      ServiceKey: '',
    }
    this.setState({ emailSubmitting: true })
    fetch(`${config.RECAPTCHA_CLIENT}/physician-finder.php/ShareSite`, {
      method: 'POST',
      body: JSON.stringify(emailPostBody),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          this.setState(
            {
              showMessageSent: true,
              emailSubmitting: false,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  showMessageSent: false,
                })
                this.closeShareModal()
              }, 3800)
            }
          )
        }
      })
  }

  updateInputValue(evt) {
    this.setState({
      [evt.target.id]: evt.target.value,
    })
    // this[evt.target.id] = evt.target.value
  }

  closeShareModal() {
    this.setState({
      showShareModal: false,
    })
  }

  shareTracking() {
    if (this.props.emailFinderTracking) {
      trackGa(this.props.emailFinderTracking.trackingShare)
    } else if (!this.props.emailFinderTracking) {
      return
    } else {
      trackGa('find sleep specialist,	click,	share sleep specialist locator - find sleep specialist main')
    }
  }

  joinTracking() {
    if (this.props.emailFinderTracking) {
      return this.props.emailFinderTracking.trackingJoin
    } else {
      return 'find sleep specialist,	click,	join directory - find sleep specialist main'
    }
  }
  updateTracking() {
    if (this.props.emailFinderTracking) {
      return this.props.emailFinderTracking.trackingUpdate
    } else {
      return 'find sleep specialist,	click,	update listing - find sleep specialist main'
    }
  }
}

EmailFinderComponent.propTypes = {
  emailFinderTracking: PropTypes.any,
}

export default EmailFinderComponent
