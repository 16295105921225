import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SiteLink from '@components/SiteLink'
import { win } from '@src/app-utils'

import './physician-list.component.scss'

export class PhysicianList extends Component {
  constructor(props) {
    super(props)
    this.physicianListContainer = null
    this.requesting = false
    this.state = {
      requesting: false,
    }

    this.handleScroll = this.handleScroll.bind(this)
    this.assignPhysicianListContainerRef = this.assignPhysicianListContainerRef.bind(this)
  }

  render() {
    return (
      <div>
        <div id="scroll-section" ref={this.assignPhysicianListContainerRef} className="results-list-wrapper">
          {!this.props.loading && (
            <div>
              {this.props.doctor.length < 1 ? (
                <div className="no-results-wrapper">
                  <div>No results found.</div>
                  <p>To see more results, try changing location, or panning and zooming the map</p>
                </div>
              ) : (
                <div>
                  {this.props.doctor.map((doctor) => (                  <div key={doctor.Id}>
                      <div onClick={this.props.setPage}>
                        <SiteLink
                          to="/stay-connected/physician-details/"
                          temp="test"
                          params={{
                            physicianId: doctor.Id,
                            markerNumber: doctor.markerNumber ? doctor.markerNumber : 1,
                            distanceFrom: doctor.DistanceFromRequest,
                          }}
                        >
                          <div className="results-item-wrapper">
                            <div className="number-wrapper">
                              <div className="result-index">
                                <div className="index">{doctor.markerNumber}</div>
                              </div>
                            </div>
                            <div className="doctor-arrow">
                              <div className="doctor-details">
                                <h4 className="name">
                                  {doctor.First} {doctor.Last}, {doctor.ProfessionalDesignation}
                                </h4>
                                <div className="address-wrapper">
                                  <p>{doctor.Address1}</p>
                                  <p>{doctor.Address2}</p>
                                  <p>
                                    {doctor.City}, {doctor.State} {doctor.Zip}
                                  </p>
                                </div>
                                <p className="phone-number">{doctor.Phone}</p>
                                <p className="distance">{Math.round(doctor.DistanceFromRequest)} miles away</p>
                              </div>
                              <div className="physician-arrow">
                                <FontAwesomeIcon className="arrow-right" icon="angle-right" />
                              </div>
                            </div>
                            {doctor.PediatricSpecialist === true && (
                              <p className="pediatric-specialist">
                                <FontAwesomeIcon icon="check-circle" /> Pediatric patients welcome
                              </p>
                            )}
                          </div>
                        </SiteLink>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          <div className="loader-wrapper">{this.props.loading ? <div className="load" /> : null}</div>
        </div>
      </div>
    )
  }

  handleScroll() {
    const node = document.getElementById('scroll-section')
    const scrollHeight = node.scrollHeight - node.offsetHeight - 10

    function noScroll() {
      node.scrollTo(0, scrollHeight)
    }

    if (this.props.firstSearch && node.scrollTop >= scrollHeight && this.props.doctor.length < 30) {
      this.physicianListContainer.addEventListener('scroll', noScroll())
      this.props.getPhysicians().then(() => {
        if (win && window.innerWidth < 768) {
          node.scrollTo({
            top: scrollHeight + node.offsetHeight / 1.45,
            left: 0,
            behavior: 'smooth',
          })
        } else {
          node.scrollTo({
            top: scrollHeight + node.offsetHeight / 1.37,
            left: 0,
            behavior: 'smooth',
          })
        }
        this.physicianListContainer.removeEventListener('scroll', noScroll())
      })
    }
  }

  assignPhysicianListContainerRef(target) {
    this.physicianListContainer = target
    if (this.physicianListContainer) {
      this.physicianListContainer.addEventListener('scroll', this.handleScroll)
    }
  }
}

PhysicianList.propTypes = {
  doctor: PropTypes.array,
  getPhysicians: PropTypes.func,
  loading: PropTypes.bool,
  setPage: PropTypes.func,
  firstSearch: PropTypes.bool,
}

export default PhysicianList
