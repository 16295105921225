/* eslint-disable no-console */
import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

import Layout from '@components/layout/Layout'
import MapContainer from '@components/locate-sleep-specialist/map/map-container'
import EmailFinderComponent from '@components/locate-sleep-specialist/email-finder/email-finder.component'
import PhysicianList from '@components/locate-sleep-specialist/physician-list/physician-list.component'
import SiteLink from '@components/SiteLink'
import MapPlaceholder from '@assets/images/map-placeholder.png'
import { trackGa } from '@components/GoogleTracking'
import { win } from '@src/app-utils';
import config from "@utils/config"

import './physician-results.scss'

const REQUEST_LIMIT = win && window.innerWidth > 749 ? 10 : 30

const emailFinderTracking = {
  trackingShare: 'find sleep specialist,	click,	share specialist locator - find specialist results',
  trackingJoin: 'find sleep specialist,	click,	join directory - find specialist results',
  trackingUpdate: 'find sleep specialist,	click,	update listing - find specialist results'
}
export class PhysicianResults extends Component {
  constructor(props) {
    super(props)
    this.state = {
      physicianData: [],
      loading: false,
      mapAreaChange: false,
      removeMarkers: true,
      clearMapDirections: true,
      viewMapMobile: false,
      classNameTest: 'pre-open-map',
      desktopWidthAcheived: false,
      newBounds: null,
      showModal: false,
      firstSearch: true,
      redoPhysicianData: []
    }
    this.pageContainer = null

    let pageNumber = win && localStorage.getItem('pageNumber') || 1
    // this attempts to "clean up" for future page views. using local storage to try to account for browser back button
    if (pageNumber) win && localStorage.removeItem('pageNumber')

    this.API = `${config.REACT_APP_API_HOST}/physician-finder.php/RecordSearch?`
    this.queryParams = {
      lat: win && localStorage.getItem('lat'),
      lng: win && localStorage.getItem('lng'),
      GoogleAnalyticsCampaignCode: '',
      serviceKey: '',
      ItemCount: REQUEST_LIMIT * pageNumber,
      pageNumber: pageNumber,
      pediatricSpecialistsOnly: false
    }

    this.getPhysicians = this.getPhysicians.bind(this)
    this.redoSearch = this.redoSearch.bind(this)
    this.showMapMobile = this.showMapMobile.bind(this)
    this.pediatricSpecialistsOnlyChanged = this.pediatricSpecialistsOnlyChanged.bind(this)
    this.setPageStorage = this.setPageStorage.bind(this)
    this.showPhysicianDetails = this.showPhysicianDetails.bind(this)
    this.handleScroll = this.handleScroll.bind(this)

    this.mapBoundsMoved = this.mapBoundsMoved.bind(this)
    this.clickedModal = this.clickedModal.bind(this)
    this.physicianResultFindings = this.physicianResultFindings.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)

    this.getPhysicians()
    this.determineMapZoomMobleDesktop()
  }

  render() {
    return (
      <Layout addedClass="page-physician-results" location={this.props.location}>
        <div className="primary-container">
          <div>
            <h1 className="main-page-header">Select a Sleep Specialist</h1>
            <div className="select-sub-info">
              <p className="results-location">
                Results near <span> {win && localStorage.getItem('placeName')} </span>
                <SiteLink
                  to="/stay-connected/locate-a-sleep-specialist/"
                  tracking="find sleep specialist, click, change location - find specialist results "
                >
                  {' '}
                  change location{' '}
                </SiteLink>
              </p>
              <div className="ped-filter">
                <input
                  type="checkbox"
                  id="ped-patients-checkbox"
                  className="ped-checkbox"
                  checked={this.queryParams.pediatricSpecialistsOnly}
                  onChange={this.pediatricSpecialistsOnlyChanged}
                  onClick={() => {
                    this.trackCheckbox()
                  }}
                />
                <label htmlFor="ped-patients-checkbox" />
                <label>Show only providers who treat pediatric patients</label>
              </div>
            </div>
          </div>
        </div>

        <div className="results-wrapper">
          <div className="desktopleft-mobilebottom">
            <div className="co-list">
              <PhysicianList
                doctor={this.state.physicianData}
                getPhysicians={this.getPhysicians}
                loading={this.state.loading}
                setPage={this.setPageStorage}
                redoBounds={this.state.bounds}
                firstSearch={this.state.firstSearch}
              />
            </div>
          </div>

          <div className="map-wrapper desktopright-mobiletop">
            {this.state.viewMapMobile ? null : (
              <div className="mobile-map-placeholder" onClick={this.showMapMobile}>
                <h5>
                  View Map <FontAwesomeIcon icon="expand" />
                </h5>
                <img src={MapPlaceholder} />
              </div>
            )}
            <div className={this.state.classNameTest}>
              <MapContainer
                mapData={this.state.physicianData}
                bounds={this.state.bounds}
                redoSearchNeeded={this.state.redoSearchNeeded}
                redoSearch={this.redoSearch}
                displayPhysicianDetails={this.state.showDetails}
                removeMarkersNeeded={this.state.removeMarkers}
                mapDirections={this.state.mapDirections}
                clearMapDirections={this.state.clearMapDirections}
                startingLocation={this.state.startingLocation}
                showPhysicianDetails={this.showPhysicianDetails}
                mapBoundsMoved={this.mapBoundsMoved}
                showModal={this.state.showModal}
                firstSearch={this.state.firstSearch}
                redoPhysdata={this.state.redoPhysicianData}
              />
            </div>
          </div>
          {this.state.showModal && !this.state.showDetails && win && !localStorage.getItem('searchFromHome') ? (
            <div className="redo-search-wrapper">
              <h4>Find Additional Specialists:</h4>
              <p>Click below to see Sleep Specialists on this section of the map.</p>
              <button className="redo-search-button" onClick={() => this.clickedModal(this.state.newBounds)}>
                Redo Search
              </button>
            </div>
          ) : null}
        </div>

        <EmailFinderComponent emailFinderTracking={emailFinderTracking} />
      </Layout>
    )
  }

  handleScroll() {
    if (win && window.innerWidth < 750 && window.innerWidth > 500) {
      if (window.innerHeight + window.scrollY + 600 >= document.body.offsetHeight) {
        this.getPhysicians()
      }
    } else if (win && window.innerWidth <= 499) {
      if (win && window.innerHeight + window.scrollY + 900 >= document.body.offsetHeight) {
        this.getPhysicians()
      }
    }
  }

  setPageStorage() {
    localStorage.setItem('pageNumber', this.queryParams.pageNumber - 1)
  }

  findBounds(results) {
    return {
      north: Math.max.apply(
        Math,
        results.map(function(physician) {
          return physician.Lat
        })
      ),
      south: Math.min.apply(
        Math,
        results.map(function(physician) {
          return physician.Lat
        })
      ),
      east: Math.max.apply(
        Math,
        results.map(function(physician) {
          return physician.Lng
        })
      ),
      west: Math.min.apply(
        Math,
        results.map(function(physician) {
          return physician.Lng
        })
      )
    }
  }

  pediatricSpecialistsOnlyChanged() {
    this.queryParams.pediatricSpecialistsOnly = !this.queryParams.pediatricSpecialistsOnly
    this.queryParams.pageNumber = 1

    this.setState(
      {
        physicianData: [],
        redoSearchNeeded: false,
        removeMarkers: true
      },
      () => {
        this.getPhysicians()
      }
    )
  }

  async getPhysicians(redoBounds) {
    if (!this.state.loading && this.state.physicianData.length < 30) {
      let queryString = `Lat=${
        this.queryParams.lat
      }&Lng=${
        this.queryParams.lng
      }&ItemCount=${
        this.queryParams.ItemCount
      }&PageNumber=${
        this.queryParams.pageNumber
      }&PediatricSpecialistsOnly=${
        this.queryParams.pediatricSpecialistsOnly
      }`

      this.setState({
        loading: true
      })

      try {
        const data = await fetch(this.API + queryString)
        const jsonData = await data.json()


        if (jsonData) {
          const physiciansInNewBounds = []
          if (redoBounds) {
            for (let physician of jsonData.Results) {
              if (physician.Lat < redoBounds.north && physician.Lat > redoBounds.south && physician.Lng > redoBounds.west && physician.Lng < redoBounds.east) {
                physiciansInNewBounds.push(physician)
              }
            }
            this.setState({
              redoPhysicianData: physiciansInNewBounds
            })
          }
          this.setState(
            prevState => ({
              physicianData: redoBounds ? this.state.redoPhysicianData : [...prevState.physicianData, ...jsonData.Results],
              // bounds: physiciansInNewBounds.length > 0 ? this.findBounds(physiciansInNewBounds) : this.findBounds(data.Results),
              bounds: redoBounds ? this.physicianResultFindings(physiciansInNewBounds) : this.findBounds(jsonData.Results),
              loading: false,
              removeMarkers: true
            }),
            () => {
              this.assignPhysiciansNumbers()
              //this calculation is necessary to ensure when returning from a details view, when more than 10 results are loades initially, on the next scroll the page number is correct
              this.queryParams.pageNumber = this.state.physicianData.length / 10 + 1
              this.queryParams.ItemCount = 10
            }
          )
        }
      } catch(err) {
        this.setState({
          loading: false
        })
        throw err
      }
    }
  }



  physicianResultFindings(physiciansInNewBounds) {
    if (physiciansInNewBounds.length > 0) {
      return this.findBounds(physiciansInNewBounds)
    } else {
      // this is the area we will have 0 results for redo search go
      // this.setState({
      //   somethingSomething: 'noResults'
      // })
    }
  }

  mapBoundsMoved(newBounds) {
    this.setState({
      newBounds: newBounds,
      showModal: true,
      firstSearch: false
    })
  }

  clickedModal(newBounds) {
    this.setState({
      newBounds: null,
      showModal: false
    }, () => {
      this.redoSearch(newBounds)
    })
  }

  changePlaceName(location) {
    new window.google.maps.Geocoder().geocode({
      location: {lat: (location.north + location.south) / 2,lng: (location.east + location.west) / 2}
    }, this.extractCityAddr)
  }

  extractCityAddr(results, status) {
    if (status === 'OK' && results[0]) {
      const { address_components } = results[0]
      const addressEl = address_components
        ? address_components.filter(addrChunk =>
          addrChunk.types.includes('locality')
        )
        : []
      if (addressEl.length > 0) {
        const newName = addressEl[0].short_name
        localStorage.setItem('placeName', newName)
        this.setState({ placeName: newName })
      }
    }
  }

  redoSearch(redoBounds) {
    const prevQueryParams = this.queryParams
    var lat = redoBounds.center.lat().toString()
    var lng = redoBounds.center.lng().toString()

    this.changePlaceName(redoBounds)

    localStorage.setItem('lat', lat)
    localStorage.setItem('lng', lng)

    this.queryParams = Object.assign(prevQueryParams, {
      lng: lng,
      lat: lat,
      GoogleAnalyticsCampaignCode: '',
      serviceKey: '',
      ItemCount: 30,
      pageNumber: 1
    })

    this.setState({
      bounds: redoBounds,
      physicianData: [],
      removeMarkers: true,
      viewMapMobile: true
    }, () => {
      this.getPhysicians(redoBounds)
    })

  }

  showMapMobile() {
    this.setState(
      {
        redoSearchNeeded: false,
        removeMarkers: true,
        viewMapMobile: true,
        classNameTest: 'post-open-map'
      },
      () => {
        this.setState({
          redoSearchNeeded: false
        })
      }
    )
  }

  showPhysicianDetails(doctor) {
    this.setPageStorage()

    this.props.history.push(`/stay-connected/physician-details/${doctor.Id}/${doctor.markerNumber}`)
  }

  assignPhysiciansNumbers() {
    let physicians = this.state.physicianData
    for (let i = 0; i < physicians.length; i++) {
      physicians[i].markerNumber = (i + 1).toString()
    }

    this.setState({
      physicianData: physicians
    })
  }

  determineMapZoomMobleDesktop() {
    let startingWidth = win && window.innerWidth
    if (startingWidth > 750) {
      this.setState({
        desktopWidthAcheived: true
      })
    } else if (this.state.desktopWidthAcheived === false && !this.state.viewMapMobile) {
      this.resizeOnWindowResize = this.resizeOnWindowResize.bind(this)
      window.addEventListener('resize', this.resizeOnWindowResize)
    }
  }

  resizeOnWindowResize(event) {
    if (event.target.innerWidth > 750) {
      this.setState({
        bounds: this.state.bounds,
        desktopWidthAcheived: true
      })

      window.removeEventListener('resize', this.resizeOnWindowResize)
    }
  }

  trackCheckbox() {
    if (this.queryParams.pediatricSpecialistsOnly) {
      trackGa('find sleep specialist, uncheck, uncheck peds filter')
    } else {
      trackGa('find sleep specialist, check, check peds filter')
    }
  }
}

PhysicianResults.propTypes = {
  history: PropTypes.any
}

export default PhysicianResults
